<template>
  <div class="homeHeader">
    <div class="logoArea">
      <img src="../assets/newLogo.png" alt="">
      <span>透明 · 智慧 · 共享</span>
    </div>

    <div class="rightCon">
      <el-select v-model="currentTenantGuid" placeholder="请选择公司" @change="changeCompany">
        <el-option v-for="item in companyOption" :key="item.tenantGuid" :label="item.tenantName" :value="item.tenantGuid">
        </el-option>
      </el-select>
      <el-dropdown @command="handleCommand">
        <div class="login-out">
          <img class="avatar" src="../assets/avatar.png" alt="" />
          <span class="tel">{{ staffNameAndPosition }}</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-if="wechatUserNo" command="d">当前微昵称：{{ wechatUserNo }}</el-dropdown-item>
          <el-dropdown-item command="a">修改密码</el-dropdown-item>
          <el-dropdown-item command="b">退出登录</el-dropdown-item>
          
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog :title="'修改密码'" :visible.sync="dialogFormVisible" width="550px">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="新密码:" prop="pwd">
          <el-input v-model="ruleForm.pwd" autocomplete="off" class="w100f" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认新密码:" prop="confirmPwd">
          <el-input v-model="ruleForm.confirmPwd" autocomplete="off" class="w100f" show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button plain size="medium" @click="dialogFormVisible = false">取消</el-button>
        <el-button size="medium" type="info" @click="getUpdatePwd('ruleForm')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'header',
  props: {
    companyOption: {
      type: Array,
      default: []
    }
  },
  data() {
    let  userInfo = JSON.parse(localStorage.getItem('userInfo'))
    return {
      wechatUserNo:null,
      ruleForm: {
        pwd: '',
        confirmPwd: '',
      },
      userGuid: '',
      rules: {
        pwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' },
        ],
        confirmPwd: [
          { required: true, message: '请输入确认新密码', trigger: 'blur' },
          { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' },
        ],
      },
      dialogFormVisible: false,
      mobileNo:userInfo.mobileNo.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2'),
      staffNameAndPosition: userInfo.staffName + ' ' + (userInfo.positionNames ? userInfo.positionNames[0] : ''),
      currentTenantGuid: '', //当前公司
    };
  },
  created() {
    if (!localStorage.token) {
      this.$router.go(-1);
    }
    this.userGuid = localStorage.userId;
    this.currentTenantGuid = this.$route.query.currentTenantGuid;
    this.getNickName();
  },
  methods: {
        //获取微信昵称
    getNickName(){
      this.api.get('/ms-common-admin-platform-auth-service/user/data/get-webchat-user-no').then(res => {
        if (res.data.code == '00000') {
          this.wechatUserNo = res.data.data;
        }
      });
    },
    changeCompany() {
      this.$emit('changeCompany', this.currentTenantGuid)
    },
    handleCommand(res) {
      if (res == 'a') {
        //修改密码
        this.dialogFormVisible = true;
        this.ruleForm.pwd = '';
        this.ruleForm.confirmPwd = '';
      }
      if (res == 'b') {
        //退出登陆
        this.$router.push('/login')
      }
      if (res == 'd') {
        this.$confirm('已绑定微信：' + this.wechatUserNo, '微信绑定', {
          confirmButtonText: '解除绑定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.api.put('/ms-common-admin-platform-auth-service/user/data/unbind-webchat-user').then(res => {
            if (res.data.code == '00000') {
              this.wechatUserNo = null;
              this.$message.success('解除绑定成功');
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
      }
    },
    getUpdatePwd(formName) {
      let self = this;
      self.$refs[formName].validate((valid) => {
        if (valid) {
          if (self.ruleForm.pwd != self.ruleForm.confirmPwd) {
            self.$message.error('新密码与确认密码不一致，请重新输入！');
            return;
          }
          let pwd = self.$md5(self.ruleForm.pwd);
          let promise = `pwd=` + pwd + `&&` + `userGuid=` + self.userGuid;
          self.api.putform('/ms-common-admin-platform-auth-service/user/data/updatePwd', promise).then((res) => {
            if (res.data.code == '00000') {
              self.dialogFormVisible = false;
              self.$message.success('修改密码成功');
            } else {
              self.$message.error(res.data.msg);
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.homeHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}
.rightCon {
  padding: 0 32px;
  display: flex;
  align-items: center;
}
.rightCon .login-out {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rightCon .avatar {
  width: 30px;
  height: 30px;
}
.rightCon .tel {
  font-size: 14px;
  color: #333;
  margin-left: 10px;
}

.logoArea {
    display: flex;
    align-items: flex-end;
    padding-left: 1.5rem;
  }

  .logoArea img {
    height: 1.8rem;
    margin-right: 1.875rem;
  }

  .logoArea span {
    font-size: 1.1rem;
    color: #323233;
  }

  .rightCon /deep/ .el-input__inner {
    border: none;
    padding: 0;
    font-size: 16px;
    color: #4D4D4D;
    font-family: 'PingFang SC';
    padding-right: 30px;
    background: none;
    line-height: 30px;
    height: 30px;
    text-align: right;
  }
  .rightCon /deep/ .el-input__icon{
    line-height: 30px;
  }

  .rightCon /deep/ .el-select-dropdown__item.selected {
    color: #4fa1a4;
  }

  .rightCon /deep/ .el-select .el-input .el-select__caret {
    height: auto;
    font-size: 1.2rem;
    color: #4D4D4D;
  }
  .rightCon /deep/ .el-select{
    margin-right: 20px;
  }
</style>
